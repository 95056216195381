<script lang="ts" setup>
import dayjs from 'dayjs'
import LineSettings04 from '@docue/docue-ui-v2/icons/LineSettings04.vue'
import LineInfoCircle from '@docue/docue-ui-v2/icons/LineInfoCircle.vue'
import LineArrowCircleUp from '@docue/docue-ui-v2/icons/LineArrowCircleUp.vue'

const { isMainSubscriptionInTrial, mainSubscription, trialSubscription } = useSubscription()
const { formatRelativeTime } = useIntl()
const now = useNow({ interval: 60_000 })

const trialEndsWithoutValidSubscription = computed(() =>
  // check with !== 'activate_subscription' to show trial message
  // in cases where end action is not stored on the backend, to be backward-compatible
  isMainSubscriptionInTrial.value && mainSubscription.value?.trial_end_action !== 'activate_subscription',
)

const isSetupSubscription = computed(() =>
  isMainSubscriptionInTrial.value
  && mainSubscription.value?.trial_end_action === 'activate_subscription',
)

const createRelativeDueDateTextComputed = (subscription: Ref<App.Data.Subscription.SubscriptionData | undefined>) =>
  computed(() => {
    if (!subscription.value?.trial_ends_at)
      return null

    const days = dayjs(subscription.value.trial_ends_at).diff(now.value, 'day')

    return formatRelativeTime(days, 'day')
  })

const relativeDueDateText = createRelativeDueDateTextComputed(mainSubscription)
const relativeDueDateTextTrialSubscription = createRelativeDueDateTextComputed(trialSubscription)
</script>

<template>
  <div
    class="mx-2.5 flex flex-col items-center rounded-lg bg-gray-50 p-3 text-center font-sans"
  >
    <template v-if="trialEndsWithoutValidSubscription">
      <LineArrowCircleUp class="size-5 text-gray-600" />
      <p class="mb-3 mt-2 text-xs text-gray-800">
        {{ $t('sidebar.trial.message', { due: relativeDueDateText }) }}
      </p>

      <DTButton
        variant="blue"
        type="button"
        size="sm"
        class="w-full"
        to="/subscribe"
        external
      >
        {{ $t('sidebar.trial.cta') }}
      </DTButton>
    </template>
    <template v-else-if="isSetupSubscription">
      <LineSettings04 class="size-5 text-gray-600" />
      <i18n-t
        keypath="sidebar.setup.message"
        tag="p"
        class="mb-0 mt-2 text-xs text-gray-800"
      >
        <template #due>
          <span>{{ relativeDueDateText }}</span>
        </template>
        <template #plan>
          <strong>{{ mainSubscription?.plan_name }}</strong>
        </template>
      </i18n-t>
    </template>
    <template v-else>
      <LineInfoCircle class="size-5 text-gray-600" />
      <p class="mb-0 mt-2 text-xs text-gray-800">
        {{ $t('sidebar.trial.message-activated', { due: relativeDueDateTextTrialSubscription }) }}
      </p>
    </template>
  </div>
</template>
