export default (flag: string, variant: 'test' | 'control' | string & {} = 'test', enabled: boolean = true) => {
  if (!enabled) {
    return {
      isActive: computed(() => false),
    }
  }

  const { $posthog } = useNuxtApp()

  const isActive = ref(false)

  $posthog.onFeatureFlags(() => {
    isActive.value = $posthog.getFeatureFlag(flag) === variant
  })

  return {
    isActive: computed(() => isActive.value),
  }
}
