<!-- ONLY USED IN FINLAND FOR NOW -->

<script setup lang="ts">
import hannaFiImgUrl from '~/assets/images/lawyers/hanna-fi.webp?url'
import senjaFiImgUrl from '~/assets/images/lawyers/senja-fi.webp?url'
import jaakkoFiImgUrl from '~/assets/images/lawyers/jaakko-fi.webp?url'

const lawyers = [hannaFiImgUrl, senjaFiImgUrl, jaakkoFiImgUrl]

const dayjs = useDayjs()

const chatOpenTime = dayjs().hour(9).minute(0)
const chatCloseTime = dayjs().hour(15).minute(0)

const currentTime = useNow({
  interval: 60_000,
})

const isActive = computed(() => dayjs(currentTime.value).isBetween(chatOpenTime, chatCloseTime, 'minute'))

const { $posthog } = useNuxtApp()
const onClick = () => {
  $posthog.capture('lawyer_chat_clicked')
}
</script>

<template>
  <div class="flex flex-col gap-2.5 px-4.5 pb-4 text-gray-800">
    <div
      class="flex flex-row-reverse items-center gap-0.5"
      style="justify-content: start;"
    >
      <div
        v-for="(lawyerImgUrl, index) in lawyers"
        :key="`lawyer-${index}`"
        class="h-8 w-6"
      >
        <img
          :src="lawyerImgUrl"
          class="relative block size-8 max-w-8 shrink-0 rounded-full ring-2 ring-white"
        >
      </div>
    </div>

    <div class="flex flex-col gap-1">
      <div class="flex items-center gap-2">
        <span class="text-sm font-semibold">Kysy juristilta</span>
        <div class="grid grid-cols-1 grid-rows-1">
          <span
            v-if="isActive"
            class="col-start-1 row-start-1 size-2 animate-ping rounded-full bg-green-500"
            style="animation-duration: 2s;"
          />
          <span
            class="col-start-1 row-start-1 size-2 rounded-full"
            :class="isActive ? 'bg-green-500' : 'bg-gray-500'"
          />
        </div>
      </div>
      <span class="text-xs">Juristimme auttavat maksutta kysymyksissäsi.</span>
    </div>

    <!-- Chat trigger is handled by Intercom -->
    <!-- eslint-disable tailwindcss/no-custom-classname -->
    <DTButton
      class="lawyer-chat-button"
      size="xs"
      @click="onClick"
    >
      Avaa chat
    </DTButton>
  </div>
</template>
