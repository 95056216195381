<script lang="ts" setup>
const { workspace } = useAuth()
const { hasTrialSubscription, hasPaidSubscription } = useSubscription()
const { country } = useAuth()

const { isActive: isLawyerChatActive } = useTest('fi-lawyer-chat', 'test', !hasPaidSubscription.value && country.value === 'FI')
const { enabled: isLawyerChatFeatureEnabled } = useFeatureEnabled('lawyer-chat')

const isChatOmittedAsConsumerWorkspace = computed(() => workspace.value?.attributes.workspaceType === 'consumer' && !hasPaidSubscription.value)

const isLawyerChatEnabled = computed(() =>
  country.value === 'FI'
  && !isChatOmittedAsConsumerWorkspace.value
  && (isLawyerChatActive.value || hasPaidSubscription.value)
  && isLawyerChatFeatureEnabled.value,
)
</script>

<template>
  <aside
    class="hidden w-60 flex-col border-r border-gray-200 md:flex"
  >
    <AppNavigation class="flex-1 overflow-y-auto px-2.5 py-4" />

    <LawyerChat
      v-if="isLawyerChatEnabled"
    />

    <TrialAlert
      v-if="hasTrialSubscription"
      class="my-2.5"
    />

    <GracePeriodCard
      v-if="!!workspace?.attributes.retention_starts_at && !!workspace?.attributes.grace_starts_at"
      :grace-starts-at="workspace?.attributes.grace_starts_at"
      :grace-ends-at="workspace?.attributes.retention_starts_at"
      class="m-2.5"
    />
  </aside>
</template>
